import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { Table, Layout, Breadcrumb } from 'antd';
import { Link } from "react-router-dom";
import { getQuotes, maxCharsQuoteDescription, itemsPerPageQuote } from '../../services/util';
import Utilities from '../../Utilities';
import axios from 'axios';

const {
    Content
  } = Layout;

let Quotes = (props) => {

    let routes = [{
        path: '/',
        breadcrumbName: 'Inicio'
    }];

    routes.push({
        path:props.history.location.pathname, 
        breadcrumbName: Utilities.getPageName(props.history.location.pathname)
    });

    const itemRender = (route, params, routes, paths) => {
        const last = routes.indexOf(route) === routes.length - 1;
        return last ? <span>{route.breadcrumbName}</span> : <Link to={paths.join('/')}>{route.breadcrumbName}</Link>;
    }

    const [data, setData] = useState([]);    

    const columns = [
        {
          title: 'Cotización',
          dataIndex: 'requestno',
          key: 'requestno',
          defaultSortOrder: 'descend',
          sorter: (a, b) => {return parseInt(a.requestno) - parseInt(b.requestno)},
          render: text => <>{Utilities.formatNumber(text)}</>
        },
        {
          title: 'Código',
          dataIndex: 'itemnoexternal',
          key: 'itemnoexternal',
          sorter: (a, b) => {return a.itemnoexternal.localeCompare(b.itemnoexternal)},
          render: text => <Link title="Ver producto" to={"/search/"+String(text)}>{String(text).trim().toUpperCase()}</Link>
        },
        {
          title: 'Categoría',
          dataIndex: 'categorydescription',
          key: 'categorydescription',
          sorter: (a, b) => {return a.categorydescription.localeCompare(b.categorydescription)},
          render: text => <span className="capitalizeFirstLetter">{String(text).trim().toLowerCase()}</span>
        },
        
        {
          title: 'Descripción',
          dataIndex: 'itemdescription',
          key: 'itemdescription',
          sorter: (a, b) => {return a.itemdescription.localeCompare(b.itemdescription)},
          render: text => 
            <span className="capitalizeFirstLetter" title={String(text).trim().toLowerCase()}>
              {
                String(text).trim().length > maxCharsQuoteDescription 
                ? <span>{String(text).trim().toLowerCase().substring(0, maxCharsQuoteDescription)+'...'}</span> 
                : String(text).trim().toLowerCase()
              }
            </span>
        },
        {
          title: 'Fecha',
          dataIndex: 'transactiondate',
          key: 'transactiondate',
          sorter: (a, b) => {return new Date(a.transactiondate) - new Date(b.transactiondate)},
          render: text => <span>{Utilities.formatDateTime(text)}</span>,
          style:{minWidth:'170px'}
        },
        {
          title: 'Unidad',
          dataIndex: 'uomdescription',
          key: 'uomdescription',
          align: 'center',
          sorter: (a, b) => {return a.uomdescription.localeCompare(b.uomdescription)},
          render: text => <span className="capitalizeFirstLetter">{String(text).trim().toLowerCase()}</span>
        },
        {
          title: 'Cantidad',
          dataIndex: 'transactionquantity',
          key: 'transactionquantity',
          align: 'right',
          sorter: (a, b) => {return parseInt(a.transactionquantity) - parseInt(b.transactionquantity)}
        },
        {
          title: 'Precio',
          dataIndex: 'salesprice',
          key: 'salesprice',
          align: 'right',
          render: text => <>{Utilities.formatPrice(text)}</>,
          sorter: (a, b) => {return parseFloat(a.salesprice) - parseFloat(b.salesprice)}
        },        	          
        {
          title: 'Monto',
          dataIndex: 'salestotal',
          key: 'salestotal',
          align: 'right',
          render: text => <>{Utilities.formatPrice(text)}</>,
          sorter: (a, b) => {return parseFloat(a.salestotal) - parseFloat(b.salestotal)}
        },        	          
      ];

      {/* To do list: change number format*/}

      useEffect(() => {
        (async () => {
          var bodyFormData = new FormData();
          bodyFormData.set('customerId', window.localStorage.getItem('customerId'));
          bodyFormData.set('warehouse', window.localStorage.getItem('warehouse'));  
    
          axios.post(getQuotes,bodyFormData
            ).then(res => {
                     console.log(res);
                     setData(res.data);
            });
    
        })();
      }, []);      
      
  return(
    <>

        <Content style={{ padding: '0 0px' }}>
            <div style={{width:'100%', paddingBottom:'10px', paddingLeft:'20px', paddingTop: '15px'}}>
              <Breadcrumb itemRender={itemRender} routes={routes} />
            </div>
            <div style={{width:'100%', overflowX:'auto'}}>
              <Table 
                className="clsQuoteTable"
                dataSource={data} 
                columns={columns} 
                pagination={{
                  defaultPageSize: itemsPerPageQuote,
                  pageSizeOptions: ["10", "20", "25", "30", "50", "100"], 
                  showSizeChanger: true,
                  position:'both',
                  style:{float:'left'},
                  locale:{items_per_page:'/ página', next_page:'Página siguiente', prev_page: 'Página anterior'}
                }}
                locale={{ emptyText: '[ No se encontraron datos ]' }} />
            </div>
        </Content>
    </>
   )
}

export default Quotes