import React from 'react';
import PropTypes from 'prop-types';
import Sort from '../Sort';
import { Link } from 'react-router-dom';
import { Icon, Breadcrumb } from 'antd';
import 'antd/dist/antd.css';
import Utilities from '../../../Utilities';
import Selectbox from '../../Selectbox';
import { maxProductsResults } from '../../../services/util';

const SearchBox = props => {
  const handleKeyDown = (e) => {
    if(e.key === "Enter") {
      return props.handleSearchClick();
    }
  }

  return (
    <div className="searchBox">
      <input placeholder="Producto o categor&iacute;a" className="searchTextbox" onKeyDown={handleKeyDown}  onChange={props.handleSearchChange} value={props.searchText}/>
      <button className="searchButton" onClick={props.handleSearchClick}>
        Buscar
        <Icon type="search" style={{fontSize:'80%', paddingLeft:'5px'}} />
      </button>
    </div>
  )
}

const ShelfHeader = props => {
  let vSuffix = props.productsLength==1?'':'s';
  
  let routes = [{
    path: '/',
    breadcrumbName: 'Inicio'
  }];

  routes.push({
    path:props.history.location.pathname, 
    breadcrumbName: Utilities.getPageName(props.history.location.pathname)
  });
  
  const itemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? <span>{route.breadcrumbName}</span> : <Link to={paths.join('/')}>{route.breadcrumbName}</Link>;
  }

  if (props.headertype == 1) {

    return (
      <div className="shelf-container-header">
        <div style={{width:'100%', paddingBottom:'10px'}}>
          <Breadcrumb itemRender={itemRender} routes={routes} />
        </div>
        <SearchBox {...props}/>
        <Sort />
        <div className="products-found">
          {
            props.productsLength < maxProductsResults &&
            <span>{Utilities.formatNumber(props.productsLength)} producto{vSuffix} encontrado{vSuffix}.</span>
          }
          {
            props.productsLength >= maxProductsResults &&
            <span>Se muestran los {Utilities.formatNumber(props.productsLength)} productos mas relevantes.</span>
          }
        </div>
      </div>
    );

  } 

  if (props.headertype == 2) {

    return (
      <div className="shelf-container-header">
        <div style={{width:'100%', paddingBottom:'10px'}}>
          <Breadcrumb itemRender={itemRender} routes={routes} />
        </div>
        <SearchBox {...props}/>
      </div>
    );

  } 

  if (props.headertype == 3) {

    const handleSearchSort = (v) => {
      props.updateSort(v);
      props.searchProducts([], v, props.searchtext, "", false);
    }
  
    const sortBySearch = Utilities.getOrderingOptions();

    return (
      <div className="clsSearchHeader">
        <div style={{width:'100%', paddingTop:'10px'}}>
          <Breadcrumb itemRender={itemRender} routes={routes} />
        </div>
        <div>
          {
            props.productsLength < maxProductsResults &&
            <span style={{fontSize:'90%', color:'#808080'}}>{Utilities.formatNumber(props.productsLength)} producto{vSuffix} encontrado{vSuffix} para:</span>
          }
          {
            props.productsLength >= maxProductsResults &&
            <span style={{fontSize:'90%', color:'#808080'}}>Se muestran los {Utilities.formatNumber(props.productsLength)} productos mas relevantes para:</span>
          }
          &nbsp;&nbsp; 
          <span style={{fontSize:'160%', color:'#000000'}}>{props.searchtext}</span>
        </div>
        <div className="shelf-container-header">
          <div className="sort">
            <Selectbox options={sortBySearch} handleOnChange={handleSearchSort} defaultValue={props.sort} />
          </div>
          <SearchBox {...props}/>
        </div>
      </div>
    );

  }
};

ShelfHeader.propTypes = {
  productsLength: PropTypes.number.isRequired
};

export default ShelfHeader;
