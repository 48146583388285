import React from 'react';
import Product from './Product';

const ProductList = ({ products, toggleShowLightBox, isProductOpen }) => {
  if (!products || products.length == 0) {
    return <div className="noproductsfound">[ No se encontraron productos ]</div>
  }

  return products.map(p => {
    return <Product product={p} key={p.itemno} toggleShowLightBox={toggleShowLightBox} isProductOpen={isProductOpen}/>;
  });
};

export default ProductList;
