// src/Home.js
import React from 'react';
import { Layout } from 'antd';
import Filter from '../Shelf/Filter';
import Shelf from '../Shelf';
import LoginModal from '../LoginModal';
import { useParams } from "react-router";

const {
  Content
} = Layout;

let Store = (props) => {

  let { category, subcategory } = useParams();

  return(
    <div>
      
      <div className="clsSideMenu">
        <Filter isItem={false} category={category} subcategory={subcategory} />
      </div>
      
      <Layout style={{ background: '#fff' }}>
        
        <Layout className="clsMainArea">
          <Content style={{
            background: '#fff',
            overflow: 'initial'
            }}>
            <Shelf 
              {...props} 
              isItem={false} 
              category={category} 
              subcategory={subcategory} 
              headertype={1} />
            <LoginModal  
              history={props.history}
              handleCancel={props.handleCancel} 
              handleOk={props.handleOk} 
              visible={props.visible} 
              handleLogin={props.handleLogin} 
              handleLogoff={props.handleLogoff}/>
          </Content>
        </Layout>  
      </Layout>
  </div>

    )
}

export default Store;