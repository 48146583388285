import { UPDATE_CART } from './actionTypes';

export const updateCart = cartProducts => dispatch => {
  let productQuantity = cartProducts.reduce((sum, p) => {
    sum += parseInt(p.quantity);
    return parseInt(sum);
  }, 0);


  let totalPrice = cartProducts.reduce((sum, p) => {
    const price = (p.salesprice == null || String(p.salesprice).trim() == '') ? 0 : p.salesprice;
    sum += price * p.quantity;
    return sum;
  }, 0);

  let installments = cartProducts.reduce((greater, p) => {
    greater = p.installments > greater ? p.installments : greater;
    return greater;
  }, 0);

  let cartTotal = {
    productQuantity,
    installments,
    totalPrice,
    currencyId: 'QTZ',
    currencyFormat: 'Q'
  };

  //update products priceSchedule
  cartProducts.map((product) => {
    product.priceSchedule = window.localStorage.getItem('priceSchedule');
  });
  
  console.log("=== my cart ====>");
  console.log(cartProducts);

  dispatch({
    type: UPDATE_CART,
    payload: cartTotal
  });
};
