import React from 'react';

import 'antd/dist/antd.css';
import { Form, Input,Icon, Layout, Button, notification, Breadcrumb } from 'antd';
import { Link } from "react-router-dom";
import Utilities from '../../Utilities';
import axios from 'axios';
import { pwdReset } from '../../services/util';
import LoginModal from '../LoginModal';

const {
    Content
  } = Layout;

let PasswordReset = (props) => {

    let routes = [{
        path: '/',
        breadcrumbName: 'Inicio'
    }];

    routes.push({
        path:props.history.location.pathname, 
        breadcrumbName: Utilities.getPageName(props.history.location.pathname)
    });

    const itemRender = (route, params, routes, paths) => {
        const last = routes.indexOf(route) === routes.length - 1;
        return last ? <span>{route.breadcrumbName}</span> : <Link to={paths.join('/')}>{route.breadcrumbName}</Link>;
    }

   const { getFieldDecorator } = props.form;

   const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        
        var bodyFormData = new FormData();

        bodyFormData.set('email', values.email);
        
        var optionAxios = {
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
          }
        }           
        
        axios.post(pwdReset, bodyFormData, optionAxios
            ).then(res => {
 
                    switch(parseInt(res.data[0].status)) {
                        case 0: {
                            notification.error({ message:'No existe ninguna cuenta asociada a este correo electrónico' });
                            break;
                        }
                        case 1: {
                            notification.success({
                                message:'Su contraseña ha sido reiniciada y enviada por correo electrónico',
                                onClose: () => { props.history.push('/'); },
                                duration: 2
                            });
                            break;
                        }
                        default: {
                            notification.error({ message:'Error desconocido' });
                            break;
                        }
                     }

            });
    };
  })
}


  return(
    <>
        <Content style={{ padding: '0 0px' }}>
            <div style={{width:'100%', paddingBottom:'10px', paddingLeft:'20px', paddingTop: '15px'}}>
              <Breadcrumb itemRender={itemRender} routes={routes} />
            </div>
            <Form onSubmit={handleSubmit} className="login-form">

                <Form.Item label="Correo electrónico">
                {getFieldDecorator('email', {
                    rules: [{ required: true, message: 'Por favor ingrese un correo electrónico válido' }],
                })(
                    <Input
                    prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="email"
                    placeholder="Correo electrónico asociado a su cuenta"
                    />,
                )}
                </Form.Item>

                <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button">
                    Reiniciar contraseña
                </Button>
                </Form.Item>
            </Form>   
            <LoginModal
                history={props.history}
                handleCancel={props.handleCancel} 
                handleOk={props.handleOk} 
                visible={props.visible} 
                handleLogin={props.handleLogin} 
                handleLogoff={props.handleLogoff} />       
        </Content>
    </>
   )
}

export default Form.create({ name: 'reset_password' })(PasswordReset)