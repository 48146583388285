import React, { Component } from 'react';
import { Layout } from 'antd';

import { setAnonymous } from '../../services/util';
import Utilities from '../../Utilities';
import axios from 'axios';
import { HashRouter,Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import DrawerRouterContainer from './DrawerRouterContainer.js';
import Store from './Store';
import Search from './Search';
import Landing from './Landing'
import Quotes from './Quotes';
import Product from './Product';
import Password from './Password';
import NewUser from './NewUser';
import PasswordReset from './PasswordReset';

const {
  Footer
} = Layout;

class App extends Component {
  state = { visible: false,
      name: window.localStorage.getItem('name'),
      account: window.localStorage.getItem('account'),
      priceSchedule:  window.localStorage.getItem('priceSchedule'),
      addresses:  window.localStorage.getItem('addresses'),
      customerId : window.localStorage.getItem('customerId'),
      current: 'setting:0'
         };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  sayHi = () => {
    alert('test');
  }

  handleLogin = (name, account, priceSchedule, addresses, customerId) => {
    window.localStorage.setItem('name', name);
    window.localStorage.setItem('account',account);
    window.localStorage.setItem('priceSchedule',priceSchedule);
    window.localStorage.setItem('addresses',addresses);
    window.localStorage.setItem('customerId',customerId);
    this.setState({
      name
    });
  };

  handleLogoff = () => {
    this.setState({
      name:'',
      account:''
    });    
    window.localStorage.removeItem('name');
    window.localStorage.removeItem('account');
    window.localStorage.removeItem('priceSchedule');
    window.localStorage.removeItem('addresses');
    window.localStorage.removeItem('customerId');
    window.localStorage.removeItem('categoryItem');
  };

  
  handleOk = history => {
    Utilities.redirectToHomeFromPublic(history);
    this.setState({
      visible: false
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false,
    });
  };


  render() {
    //const style = this.state.hideLogin ? {display:'none'}  : {};
    //JSX = JavaSript XML
    //var is replaced by const and let

    window.localStorage.setItem('deviceId',"portal")
    window.localStorage.setItem('mission',"BAMBINO")
    window.localStorage.setItem('warehouse',"BAM03")
    

    var bodyFormData = new FormData();
    bodyFormData.set('deviceId', window.localStorage.getItem('deviceId'));
    bodyFormData.set('warehouse', window.localStorage.getItem('warehouse'));
    bodyFormData.set('mission', window.localStorage.getItem('mission'));
    bodyFormData.set('account', window.localStorage.getItem('account'));

    var optionAxios = {
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
      }
    }     

    axios.post(setAnonymous,bodyFormData,optionAxios
      ).then(res => {
               console.log('Anonymous==',res);
               if (res.data[0].defaultpriceschedule)
               {
                  console.log('defaultPriceSchedule==',res.data[0].defaultpriceschedule);
                  window.localStorage.setItem('priceDefaultSchedule',res.data[0].defaultpriceschedule)
                  window.localStorage.setItem('storeCity',res.data[0].city)
                  window.localStorage.setItem('storeCountry',res.data[0].country)
                  window.localStorage.setItem('storeAddress',res.data[0].address)
                  window.localStorage.setItem('storeTelephone',res.data[0].telephone)
                  window.localStorage.setItem('storeFax',res.data[0].fax)
                  window.localStorage.setItem('storeContact',res.data[0].contact)
                  if (!window.localStorage.getItem('categoryItem'))
                  {
                    window.localStorage.setItem('categoryItem',res.data[0].categoryitem)
                  }
               }
      });

      const ComponentWrapper = ({ component: Component, ...rest }) => (
              <Component {...rest}
                name={this.state.name}  showModal={this.showModal}  
                current={this.state.current} handleLogoff={this.handleLogoff} visible={this.state.visible} handleCancel={this.handleCancel} handleOk={this.handleOk} handleLogin={this.handleLogin}
              />
    );

    return (
      <React.Fragment>
         <HashRouter>
            <DrawerRouterContainer showModal={this.showModal} name={this.state.name} handleLogoff={this.handleLogoff}>
              <Switch>
                <Route path='/' exact render={(props) => 
                <ComponentWrapper {...props} component={Landing}/>} />

                <Route path='/newuser' exact render={(props) => 
                <ComponentWrapper {...props} component={NewUser}/>} />

                <Route path='/search/:searchtext' exact render={(props) => 
                <ComponentWrapper {...props} component={Search}/>} />

                <Route path='/category/:category/:subcategory' exact render={(props) => 
                <ComponentWrapper {...props} component={Store}/>} />

                <Route path='/product/:id' exact render={(props) => 
                <ComponentWrapper {...props} component={Product}/>} />

                <Route path="/quotes" render={(props) => 
                <ComponentWrapper {...props} component={Quotes}/>} />

                <Route path="/password" render={(props) => 
                <ComponentWrapper {...props} component={Password}/>} />

                <Route path="/passwordreset" render={(props) => 
                <ComponentWrapper {...props} component={PasswordReset}/>} />
              </Switch>
            </DrawerRouterContainer>
          </HashRouter>            

          <Footer style={{ textAlign: 'center', background: '#fff' }}>
            <div>
              PBX <a href={"tel:"+window.localStorage.getItem('storeTelephone')}>{window.localStorage.getItem('storeTelephone')}</a>
              <br/>
              WhatsApp <a href={"tel:"+window.localStorage.getItem('storeFax')}>{window.localStorage.getItem('storeFax')}</a>
              <br/>
              {window.localStorage.getItem('storeAddress')}
              <br/>
              {window.localStorage.getItem('storeCity')}                       
              <br/>
              <a href={"mailto:"+window.localStorage.getItem('storeContact')}>{window.localStorage.getItem('storeContact')}</a>
            </div>
            ©2021 Powered by <a href='https://www.promisan.com/?id=prosis' target='_new'>Prosis</a>
          </Footer>
        
      </React.Fragment>
    );
  }
}

export default App;
