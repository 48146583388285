import { FETCH_PRODUCTS, FETCH_CATEGORIES, CLEAR_PRODUCTS, CLEAR_CATEGORIES } from './actionTypes';

const initialState = {
  products: [],
  categories: []
};

export default function(state = initialState, action) {

  switch (action.type) {
    case FETCH_PRODUCTS:
      return {
        ...state,
        products: action.payload
      };
    case FETCH_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      };
    case CLEAR_PRODUCTS:
        return {
          ...state,
          products: []
        };  
    case CLEAR_CATEGORIES:
      return {
        ...state,
        categories: []
      };     
    default:
      return state;
  }
}
