import React from 'react';

import 'antd/dist/antd.css';
import { Form, Input, Icon, Layout, Button, notification, Breadcrumb } from 'antd';
import { Link } from "react-router-dom";
import Utilities from '../../Utilities';
import axios from 'axios';
import { pwdChange } from '../../services/util';

const {
    Content
  } = Layout;

let Password = (props) => {

    let routes = [{
        path: '/',
        breadcrumbName: 'Inicio'
    }];

    routes.push({
        path:props.history.location.pathname, 
        breadcrumbName: Utilities.getPageName(props.history.location.pathname)
    });

    const itemRender = (route, params, routes, paths) => {
        const last = routes.indexOf(route) === routes.length - 1;
        return last ? <span>{route.breadcrumbName}</span> : <Link to={paths.join('/')}>{route.breadcrumbName}</Link>;
    }

   const { getFieldDecorator } = props.form;  
   

   const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        
        var bodyFormData = new FormData();

        bodyFormData.set('user', window.localStorage.getItem('account'));
        bodyFormData.set('oldPwd', values.current);
        bodyFormData.set('pwd1', values.pwd1);
        bodyFormData.set('pwd2', values.pwd2);
        

        var optionAxios = {
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
          }
        }           
        
        axios.post(pwdChange,bodyFormData, optionAxios
            ).then(res => {
 
                    switch(res.data[0].status) {
                        case 1: {
                            notification.success({ 
                                message:'Su contraseña ha sido reiniciada',
                                onClose: () => {
                                    props.history.push('/');
                                    props.handleLogoff();
                                },
                                duration: 2
                            });
                           break;
                        }
                        case 2: {
                            notification.error({ message:'Contraseña no existe' });
                            break;
                        }
                        case 3: {
                            notification.error({ message:'Contraseña actual incorrecta' });
                            break;
                        }
                        case 4: {
                            notification.error({ message:'Verifique que la nueva contraseña esta ingresada correctamente en el campo de verificación' });
                            break;
                        }
                        case 5: {
                            notification.error({ message:'La contraseña actual es igual a la nueva contraseña.' });
                            break;
                        }
                        case 6: {
                            notification.error({ message:'La nueva contraseña debe de tener un mínimo de 8 caracteres' });
                            break;
                        }

                        default: {
                            notification.error({ message:'Error desconocido' });
                            break;
                        }
                     }

            });
    };
  })
}


  return(
    <>

        <Content style={{ padding: '0 0px' }}>
            <div style={{width:'100%', paddingBottom:'10px', paddingLeft:'20px', paddingTop: '15px'}}>
              <Breadcrumb itemRender={itemRender} routes={routes} />
            </div>
            <Form onSubmit={handleSubmit} className="login-form">

                <Form.Item label="Contraseña actual">
                {getFieldDecorator('current', {
                    rules: [{ required: true, message: 'Por favor ingrese clave actual' }],
                })(
                    <Input
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder="Contraseña actual"
                    />,
                )}
                </Form.Item>

                <Form.Item label="Nueva contraseña">
                {getFieldDecorator('pwd1', {
                    rules: [{ required: true, message: 'Por favor ingrese nueva contraseña' }],
                })(
                    <Input
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder="8 caracteres mínimo"
                    />,
                )}
                </Form.Item>

                <Form.Item label="Confirmar contraseña">
                {getFieldDecorator('pwd2', {
                    rules: [{ required: true, message: 'Por favor confirme su nueva contraseña' }],
                })(
                    <Input
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder="8 caracteres mínimo"
                    />,
                )}
                </Form.Item>
                <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button">
                    Cambiar contraseña
                </Button>
                </Form.Item>
            </Form>       
        </Content>
    </>
   )
}

export default Form.create({ name: 'change_password' })(Password)