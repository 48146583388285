import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon } from 'antd';

import Thumb from '../../../Thumb';
import { addProduct } from '../../../../services/cart/actions';
import Utilities from '../../../../Utilities';
import {  Card, CardBody, CardActions, CardFooter, CardTitle, CardSubtitle } from '@progress/kendo-react-layout';

const Product = ({ product, addProduct, toggleShowLightBox }) => {
  const priceSchedule = window.localStorage.getItem('priceSchedule');

  product.quantity = 0;
  let vThisPrice = '';
  let vPriorPrice = '';
  let vContent = [];

  if (parseInt(product.priceschedule) === parseInt(priceSchedule) || (!priceSchedule && product.fielddefault === 1) ) {
    vThisPrice = Utilities.formatPrice(Math.round(product.salesprice*100)/100);
    vPriorPrice = '';
    if (product.priorprice != '') {
      vPriorPrice = Utilities.formatPrice(Math.round(product.priorprice*100)/100);
    }

    vContent.push(
        <div key={1}>
          {
            product.priceoff < 0 && 
            <div className="clsDiscountDisplay" title="Producto con descuento">
              {product.priceoffpercentage}%
              <br />
              <span style={{textDecoration:'line-through', fontSize:'60%'}}>{product.currencysymbol} {vPriorPrice}</span>
            </div>
          }
          <div align="center">
            <div style={{whiteSpace:'nowrap'}}>
              <span style={{fontWeight:'bold', fontSize:'140%'}}>
                {product.currencysymbol} {vThisPrice}
              </span>
            </div>

            <div style={{lineHeight:'7px'}}>
              <span style={{fontSize:'60%'}}>
                <span style={{fontWeight:'bold'}}>{String(product.uomdescription).trim().toUpperCase()}</span> - {product.pricescheduledescription} - CTN: {(product.offerminimumquantity != null && String(product.offerminimumquantity).trim() != '') ? Utilities.formatNumber(product.offerminimumquantity) : 'ND'}
              </span>
            </div>
    
          </div>
        </div>
    );

  }
  
  return (
 
    <Card className="shelf-item">
      

      <CardTitle>
        <div data-sku={product.itemno}>
          <div className="shelf-item__title" title={product.itemdescription.toUpperCase()}>
            {product.itemdescription.toUpperCase()}
          </div>
        </div> 
      </CardTitle>
      <CardSubtitle>
        <div style={{lineHeight:'10px',  width:'98%', paddingLeft:'1%'}} title={product.categorydescription + ' / ' + product.categoryitemname}>
          <span style={{fontSize:'70%'}}>{product.categorydescription} / {product.categoryitemname}</span>
        </div>
      </CardSubtitle>
      <CardBody>
        
        <div data-sku={product.itemno}>
          <Thumb
            classes="shelf-item__thumb"
            productList={product.PICTURE}
            toggleShowLightBox = {toggleShowLightBox}
            alt={"Ver fotografías"} />
        </div>


        </CardBody>

        <CardFooter>
          <div className="shelf-item__price" data-sku={product.itemno}>
            {vContent}
          </div>
        </CardFooter>

        <CardActions orientation='vertical' >
            <span style={{ fontSize: '70%', alignSelf: 'center', color: '#656565', paddingTop:'10px' }}>
              Existencia <span style={{fontWeight:'bold'}}>{Utilities.formatNumber(Math.floor(product.onhand))}</span>
            </span>

              <span style={{paddingBottom:'10px'}}>
              
                <div title={"Ir a producto"} style={{ fontSize:'90%', height:'15px', paddingTop:'5px'}}>
                  <Link to={`/product/${product.itemno}`}>
                  <small>
                      {product.itemnoexternal}
                  </small> 
                  </Link>
                </div>
              </span>

              <span>
              { 
                vThisPrice != '' &&
                <div 
                  className="shelf-item__buy-btn"
                  title={"Agregar al carrito de compra"}
                  onClick={() => addProduct(product)}>
                  COMPRAR
                  <Icon type="shopping-cart" style={{fontSize:'100%', paddingLeft:'5px'}} />
                </div>
              }   
              { 
                vThisPrice == '' &&   
                <div 
                  className="shelf-item__buy-btn-disabled"
                  title={"Muy pronto"}>
                  MUY PRONTO
                  <Icon type="clock-circle" style={{fontSize:'100%', paddingLeft:'5px'}} />
                </div>
              }
              </span>
        </CardActions>

    </Card>

  );
};

Product.propTypes = {
  product: PropTypes.object.isRequired,
  addProduct: PropTypes.func.isRequired
};

export default connect(
  null,
  { addProduct }
)(Product);
