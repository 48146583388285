import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Lightbox from "react-image-lightbox";
import { fetchProducts, clearProducts } from '../../services/shelf/actions';
import Spinner from '../Spinner';
import { landingOfferWidth } from '../../services/util';
import Product from '../Shelf/ProductList/Product';

import './style.scss';
import Utilities from '../../Utilities';
import { itemPicturesBase } from '../../services/util';

class Offer extends Component {
  static propTypes = {
    fetchProducts: PropTypes.func,
    clearProducts: PropTypes.func,
    products: PropTypes.array
  };

  state = {
    isLoading: false,
    isProductOpen: false,
    photoIndex: 0
  };

  componentDidMount() {
    this.props.clearProducts();
    this.setState({ isLoading: true });
    this.props.fetchProducts([], "discountASC", "", "", '1', "", () => {
      this.setState({ isLoading: false});
    });
  }

  toggleShowLightBox = (vImage ="") => {
    this.setState({
      isProductOpen: !this.state.isProductOpen,
      vImage
    })
  }

  render() {
    const { products } = this.props;
    const { isLoading, isProductOpen, vImage } = this.state;
    let vContainerWidth = 0;
    let vToggleScroll = "";
    const vOSVersion = Utilities.getOS();

    if (vOSVersion == 'Mac' || vOSVersion == 'Windows' || vOSVersion == 'Linux') {
      vToggleScroll = "toggleScroll-x";
    }

    if (products) {
      vContainerWidth = landingOfferWidth * products.length;
    }

    if (products && products.length == 0) {
      return(<div style={{height:'5px', lineHeight:'5px'}}>&nbsp;</div>);
    }

    return (
      <>
        {isLoading && <Spinner />}
        {(!products || products.length == 0) && <div></div>}
        {products && products.length > 0 &&
          <>
            <div className="titleWrapper">
              <h1 className="pullleft">Ofertas</h1>
            </div>
            <div className="shelf-container clsOffer">
              <div className={"clsOfferItemContainer " + vToggleScroll}>
                <div className="clsOfferItem" style={{minWidth:String(vContainerWidth)+'px'}}>
                <div className='k-card-deck'>
                  {
                    products.map(p => {
                      return <Product product={p} key={p.itemno} toggleShowLightBox={this.toggleShowLightBox} isProductOpen={isProductOpen}/>;
                    })
                  }
                </div>
                </div>
              </div>
            </div>
          </>
        }
        {
          isProductOpen && 
          <Lightbox
            mainSrc={itemPicturesBase + vImage[this.state.photoIndex]}
            nextSrc={itemPicturesBase + vImage[(this.state.photoIndex + 1) % vImage.length]}
            prevSrc={itemPicturesBase + vImage[(this.state.photoIndex + vImage.length - 1) % vImage.length]}            
            onCloseRequest={this.toggleShowLightBox}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (this.state.photoIndex + vImage.length - 1) % vImage.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (this.state.photoIndex + 1) % vImage.length,
              })
            }            
          />          
          
        }
      </>
    );
  }
}

const mapStateToProps = state => ({
  products: state.shelf.products
});

const mapActions = {
  fetchProducts: fetchProducts,
  clearProducts: clearProducts,
};

export default connect(
  mapStateToProps,
  mapActions
)(Offer);
