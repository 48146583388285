import { FETCH_PRODUCTS, FETCH_CATEGORIES, CLEAR_PRODUCTS, CLEAR_CATEGORIES } from './actionTypes';
import axios from 'axios';
import { productsAPI, categoriesAPI, maxProductsResults, landingTopNOffers } from '../util';

export const searchProducts = (searchText, callback) => dispatch => {
  return axios
    .get(productsAPI,{
      params: {
        searchText,
      }}
      ).then(res => {
      let products = res.data.PRODUCTS;

      if (!!callback) {
        callback();
      }

      return dispatch({
        type: FETCH_PRODUCTS,
        payload: products
      });
    })
    .catch(err => {
      console.log('Could not fetch products. Try again later.');
    });
};


export const fetchProducts = (filters, sortBy, searchText, selectedCategory, onSale, itemNo, callback) => dispatch => {
  const vParams = {
    categoryitem: filters?filters[0]:window.localStorage.getItem('categoryItem'),
    category:selectedCategory?selectedCategory:'',
    orderBy: sortBy,
    priceSchedule: window.localStorage.getItem('priceSchedule'),
    selectTopN: onSale == '1' ? landingTopNOffers : maxProductsResults,
    onSale: onSale,
    itemNo: itemNo,
    account: window.localStorage.getItem('account'),
    ...(searchText && { searchText: searchText})
  };

  return axios
    .get(productsAPI, { params: vParams }
      ).then(res => {
      
      let products = res.data.PRODUCTS;

      if (filters) {
        window.localStorage.setItem('categoryItem', filters[0]);  
      }

      if (!!callback) {
        callback();
      }

      return dispatch({
        type: FETCH_PRODUCTS,
        payload: products
      });
    })
    .catch(err => {
      console.log('Could not fetch products. Try again later.' + err);
    });
};

export const clearProducts = (callback) => dispatch => {

  if (!!callback) {
    callback();
  }

  return dispatch({
    type: CLEAR_PRODUCTS
  });  

}

export const clearCategories = (callback) => dispatch => {

  if (!!callback) {
    callback();
  }

  return dispatch({
    type: CLEAR_CATEGORIES
  });  

}

export const fetchCategories = (sort, searchText, filterCode, callback) => dispatch => {
  return axios
    .get(categoriesAPI,{
      params: {
        SearchText: String(searchText).trim().toLowerCase(),
        Category: String(filterCode).trim().toLowerCase(),
        OrderBy: String(sort).trim().toLowerCase(),
        account: window.localStorage.getItem('account'),
      }})
    .then(res => {
      let categories = res.data.CATEGORIES;
      categories = [...new Set(categories)];
      
      if (!!callback) {
        callback();
      }

      return dispatch({
        type: FETCH_CATEGORIES,
        payload: categories
      });
    })
    .catch(err => {
      console.log('Could not fetch categories. Try again later.');
    });
};
