import 'react-app-polyfill/ie11';
import 'core-js/es/string';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App';
import Root from './Root';

import '@progress/kendo-theme-bootstrap/dist/all.css';
import './index.scss';

ReactDOM.render(
  <Root>
    <App />
  </Root>,
  document.getElementById('root')
);
