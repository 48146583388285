import React, { Component } from 'react';
import { notification } from "antd";
import { Icon } from 'antd';
import { Input } from '@progress/kendo-react-inputs'
import { Button } from '@progress/kendo-react-buttons';
import './style.scss';

class ProductSearch extends Component {

  state = {
    searchText: ''
  };

  doSearchProduct = () => {
    if (this.state.searchText && this.state.searchText.length >= 3) {
      this.props.history.push('/search/'+this.state.searchText);
    } else {
      notification.info({
        message:'Su búsqueda debe contener al menos 3 caracteres.'
      });
    }
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.doSearchProduct();
      e.target.blur();
    }
  }

  handleSearchText = (e) => {
    let vSearch = String(e.target.value).trim().toLowerCase();
    this.setState({ searchText: vSearch }, () => {
      if (vSearch == '') {
        this.doSearchProduct();
      }
    });
  }

  render() {
    return (
      <div className="k-hbox" style={this.props.style}>
            <Input 
              placeholder="Buscar productos por c&oacute;digo, descripci&oacute;n o categor&iacute;a" 
              onChange={this.handleSearchText}
              onKeyDown={this.handleKeyDown}
              autoFocus
              style={this.props.styleTextBox}></Input>

            <div style={{marginLeft: 10 }}>
              <Button icon="filter"
                onClick={this.doSearchProduct}>
                  Buscar
              </Button>
            </div>
      </div>
    );
  }
}

export default ProductSearch;
