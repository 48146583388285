import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchCategories, clearCategories } from '../../services/shelf/actions';
import Spinner from '../Spinner';
import Tile from './Tile';
import Utilities from '../../Utilities';
import { defaultCategoryImage, defaultCategorySort, defaultSubcategoryType } from '../../services/util';
import { Icon } from 'antd';

import './style.scss';

class CategoryList extends Component {
  static propTypes = {
    fetchCategories: PropTypes.func,
    searchCategories: PropTypes.func,
    clearCategories: PropTypes.func,
    categories: PropTypes.array
  };

  state = {
    isLoading: false,
    searchText: ''
  };

  componentDidMount() {
    //if not defined or if it is 0 (not loaded) or 1 (1 category selected )
    if (!this.props.categories || this.props.categories.length < 2) {
      this.props.clearCategories();
      this.doFetchCategories();
      Utilities.goToTop();
    }
  }

  doFetchCategories = () => {
    this.setState({ isLoading: true });
    this.props.fetchCategories(defaultCategorySort, this.state.searchText, '', () => {
        this.setState({ isLoading: false });
    });
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.doFetchCategories();
    }
  }

  handleSearchText = (e) => {
    let vSearch = String(e.target.value).trim().toLowerCase();
    this.setState({ searchText: vSearch }, () => {
      if (vSearch == '') {
        this.doFetchCategories();
      }
    });
  }

  render() {
    const { categories } = this.props;
    const { isLoading, searchText } = this.state;

    const vNow = new Date();
    const vTick = vNow.getMilliseconds();

    return (
      <div className="container">
        {isLoading && <Spinner />}
        <div className="titleWrapper">
          <h1 className="pullleft">Categor&iacute;as</h1>
          <div className="pullright">
            <input 
              type="text" 
              className="searchTextbox" 
              placeholder="Categor&iacute;a..." 
              onChange={this.handleSearchText}
              onKeyDown={this.handleKeyDown}></input>
            <button className="searchButton" onClick={this.doFetchCategories}>
              Buscar
              <Icon type="search" style={{fontSize:'80%', paddingLeft:'5px'}} />
            </button>
          </div>
        </div>
        <div className="tileWrapper">
          {
            categories.map(cat => (
              <Tile 
                key={"i"+cat.category}
                category={cat.category} 
                categoryDescription={cat.categorydescription} 
                defaultSubcategory={Utilities.getDefaultSubcategory(defaultSubcategoryType, cat)}
                image={cat.categoryimage.trim() == '' ? defaultCategoryImage : cat.categoryimage.trim() + '?ts=' + vTick} />
            ))
          }
          {!isLoading && categories.length == 0 && 
            <h1 className="notFound">[ No se encontraron categor&iacute;as ]</h1>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
    categories: state.shelf.categories
  });

const mapActions = {
  fetchCategories: fetchCategories,
  clearCategories: clearCategories
};

export default connect(
  mapStateToProps,
  mapActions
)(CategoryList);
