import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { loadCart, removeProduct, updateProduct } from '../../services/cart/actions';
import { updateCart } from '../../services/total/actions';
import CartProduct from './CartProduct';
import Utilities from '../../Utilities';
import axios from 'axios';
import { postOrderAPI } from '../../services/util';
import { Modal, notification } from 'antd';

import './style.scss';

class FloatCart extends Component {
  static propTypes = {
    loadCart: PropTypes.func.isRequired,
    updateCart: PropTypes.func.isRequired,
    cartProducts: PropTypes.array.isRequired,
    newProduct: PropTypes.object,
    removeProduct: PropTypes.func,
    productToRemove: PropTypes.object
  };

  state = {
    isOpen: false,
    isProductOpen: false,
  };
  
  componentDidMount(){   
    this.props.updateCart(this.props.cartProducts);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.newProduct !== this.props.newProduct) {
      this.addProduct(nextProps.newProduct);
    }

    if (nextProps.productToRemove !== this.props.productToRemove) {
      this.removeProduct(nextProps.productToRemove);
    }
   
    if(nextProps.cartProducts !== this.props.cartProducts) {
      this.props.updateCart(nextProps.cartProducts);
    }
  }

  openFloatCart = () => {
    this.setState({ isOpen: true });
  };

  closeFloatCart = () => {
    this.setState({ isOpen: false });
  };

  addProduct = product => {
    Utilities.addProductToCart(
        product, 
        undefined, 
        this.props.cartProducts, 
        this.props.updateCart, 
        this.openFloatCart,
        false);
  };

  removeProduct = product => {
    const { cartProducts, updateCart } = this.props;

    const index = cartProducts.findIndex(p => p.itemno === product.itemno);
    if (index >= 0) {
      cartProducts.splice(index, 1);
      updateCart(cartProducts);
    }
  };

  proceedToCheckout = () => {
    const {
      totalPrice,
      productQuantity,
      currencyFormat,
      currencyId
    } = this.props.cartTotal;

    const { cartProducts, updateCart, history } = this.props;
    //but with ES6 we simply use

    const { confirm } = Modal;

    const fnGotoQuotesFromNotification = (pkey) => {
			notification.close(pkey);
			history.push('/quotes');
		}

    if (!productQuantity) {
      notification.error({
        message:'Debe agregar productos al carrito',
        duration:3
      });
    } else {

      
      confirm({
        title: '¿Desea ingresar esta cotización?',
        content: 'Por favor confirmar', 
        okText: 'Ok',
        cancelText: 'Cancelar',
        onOk() {
          
          var bodyFormData = new FormData();

          const aProducts = [];
    
          cartProducts.forEach(cp => {
              aProducts.push({
                              "itemno":cp.itemno,
                              "itemuom":cp.uom, 
                              "lot":0, 
                              "currency":cp.currency,
                              "quantity":cp.quantity,
                              "PriceSchedule":cp.priceSchedule
                            });
          });

          var vCart = {
            "store":{
                "mission":window.localStorage.getItem('mission'),
                "Warehouse":window.localStorage.getItem('warehouse')
             },
            "customer":{
                  "account":window.localStorage.getItem('account'),
                  "AddressId":"00000000-0000-0000-0000-0000000000000",
                  "customerId":window.localStorage.getItem('customerId')
             },
            "products":aProducts
          };
    
          bodyFormData.set('cart', JSON.stringify(vCart));
          
          axios.post(postOrderAPI, bodyFormData
              ).then(res => {
                try {
                  if (res.data.RESULT[0].status === "OK")
                  {
                    const vPostResult = Utilities.formatNumber(parseInt(res.data.RESULT[0].postresult));
                    cartProducts.splice(0, cartProducts.length);
                    updateCart(cartProducts);
                    notification.success({
                      key:"submitQuoteSuccess",
                      message:'Cotización recibida exitosamente',
                      description: 'Su número de cotización es el ' + vPostResult + '.',
                      onClick: () => { fnGotoQuotesFromNotification('submitQuoteSuccess') },
					            className: 'clsNotification',
                      duration:5
                    });
                  }
                } catch(err) {
                  notification.error({
                    message:'Hubo un problema presentando esta cotización',
                    description: 'Si el problema persiste, por comuníquese telefónicamente a Charlie Internacional para hacer su pedido',
                    duration:7
                  });
                }
                       
              });

        },
        onCancel() {},
      });      




    }
  };


  render() {
    const { cartTotal, cartProducts, removeProduct, updateProduct } = this.props;

    const products = cartProducts.map(p => {
      return (
        <CartProduct 
          key={p.itemno} 
          product={p}
          cartProducts={cartProducts} 
          removeProduct={removeProduct} 
          updateProduct={updateProduct}
          updateCart={this.props.updateCart}
          openFloatCart={this.openFloatCart}
          closeFloatCart={this.closeFloatCart} />
      );
    });

    let classes = ['float-cart'];

    if (!!this.state.isOpen) {
      classes.push('float-cart--open');
    }

    return (

      <div className={classes.join(' ')}>
	      
        {/* If cart open, show close (x) button */}
        {this.state.isOpen && (
          <div
            onClick={() => this.closeFloatCart()}
            className="float-cart__close-btn"
          >
            X
          </div>
        )}

        {/* If cart is closed, show bag with quantity of product and open cart action */}
        {!this.state.isOpen && (
          <span
            onClick={() => this.openFloatCart()}
            className="bag bag--float-cart-closed"
          >
            <span className="bag__quantity">{Utilities.formatNumber(cartTotal.productQuantity)}</span>
          </span>
        )}

        <div className="float-cart__content">
          <div className="float-cart__header">
            <span className="bag">
              <span className="bag__quantity">{Utilities.formatNumber(cartTotal.productQuantity)}</span>
            </span>
            <span className="header-title">Carrito</span>
          </div>

          <div className="float-cart__shelf-container">
            {products}
            {!products.length && (
              <p className="shelf-empty">
                Agregue productos al carrito <br />
              </p>
            )}
          </div>

          <div className="float-cart__footer">
            <div className="sub">SUBTOTAL</div>
            <div className="sub-price">
              <p className="sub-price__val">
                {cartTotal.currencyFormat}. {cartTotal.totalPrice==0?'0.00':Utilities.formatPrice(cartTotal.totalPrice)}
              </p>
              <small className="sub-price__installment">
                {!!cartTotal.installments && (
                  <span>
                    {`OR UP TO ${cartTotal.installments} x ${
                      cartTotal.currencyid
                    } ${Utilities.formatPrice(cartTotal.totalPrice)}`}
                  </span>
                )}
              </small>
            </div>
            {
              !this.props.name?
                <div onClick={() => { this.closeFloatCart(); this.props.showModal(); }} className="buy-btn">
                  Ingrese sus credenciales
                </div>
              :
              <div onClick={() => this.proceedToCheckout()} className="buy-btn">
                Cotizar
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  cartProducts: state.cart.products,
  newProduct: state.cart.productToAdd,
  productToRemove: state.cart.productToRemove,
  cartTotal: state.total.data
});

export default connect(
  mapStateToProps,
  { loadCart, updateCart, removeProduct, updateProduct }
)(FloatCart);
