import * as React from 'react';
import { withRouter,Link } from 'react-router-dom';
import { fadeIn } from 'react-animations';
import Radium, {StyleRoot} from 'radium';
import { mainLogo, mainLogoMini, whatsappImage, whatsappURL } from '../../services/util';

import { AppBar, AppBarSection, Card, CardBody } from '@progress/kendo-react-layout';
import { Toolbar, ToolbarItem, ButtonGroup, DropDownButton, DropDownButtonItem, Button } from '@progress/kendo-react-buttons';
import ProductSearch from '../App/ProductSearch';
import FloatCart from '../FloatCart'; 

const localStyles = {
  searchBar: {
    animation: 'x 0.65s',
    animationName: Radium.keyframes(fadeIn, 'fadeIn'),
    backgroundColor:'#1B3D78', 
    color:'#FFFFFF', 
    padding:10
  }
}

class DrawerRouterContainer extends React.Component {
  
    state = { showSearch: false };
 
    handleMenu = (e) => {
    
        switch(e.item.icon) {
          case "login":
            this.props.showModal();
            break;
          case "user":
            this.props.history.push('/newuser');
            break;
          case "lock":
            this.props.history.push('/passwordreset');
            break;
          default:
            
        }        
        
      } 

      handleAccount = (e) => {
    
        switch(e.item.icon) {
          case "password":
            this.props.history.push('/password');
            break;
          case "logout":
            this.handleLogOff();
            break;

          default:
            
        }        
        
      }       

      handleLogOff =(e)=>{

        this.props.handleLogoff();
        this.props.history.push('/');
        
      }

      handleQuotes =(e)=>{
        this.props.history.push('/quotes')
      }


    render() {
        return (
            <div style={{backgroundColor:'#1B3D78', minWidth:'320px'}}>
              
            <AppBar style={{backgroundColor:'#1B3D78', color:'#FFFFFF', padding:0, height:'90px'}}>

              <AppBarSection className={"clsLogoContainer"}>
                <Card style={{ backgroundColor:'#1B3D78', color:'#FFFFFF', border:0, padding:0, maxHeight:'90px' }}>
                  <CardBody>
                    <Link to="/" className="logo-corner">
                      <img src={mainLogo} width="auto" height="50px"></img>
                    </Link>
                    <Link to="/" className="logo-corner-mini">
                      <img src={mainLogoMini} width="auto" height="50px"></img>
                    </Link>
                  </CardBody>  
                </Card>
              </AppBarSection>

              <AppBarSection>
                <div>
                  <Button iconClass="k-icon k-i-search" className="searchButton-mini" onClick={() => this.setState({ showSearch: !this.state.showSearch })}></Button>
                </div>
              </AppBarSection>

              <AppBarSection>
                <div className="divSearchButtonHeader">
                  <ProductSearch {...this.props} />
                </div>
              </AppBarSection>    
    
              <AppBarSection>
                <div className='k-card-list' style={{padding:0}}>
                  <Card style={{ backgroundColor:'#1B3D78', color:'#FFFFFF', border:0 }}>
                    <CardBody style={{padding:0}}>

                    {
                      !this.props.name ?
                          <DropDownButton look="flat" icon="arrow-chevron-down" text="Inicio" onItemClick={this.handleMenu}>
                            <DropDownButtonItem text="Ingresar" icon="login" />
                            <DropDownButtonItem text="Crear Usuario" icon="user" />
                            <DropDownButtonItem text="Reiniciar" icon="lock"/>
                          </DropDownButton>
                      :
                      <Toolbar style={{ backgroundColor:'#1B3D78', color:'#FFFFFF', border:0 }}>

                        <ToolbarItem>
                          <ButtonGroup>
                            <Button iconClass="k-icon k-i-calculator" className="quotesButton"  onClick={this.handleQuotes}>Cotizaciones</Button>
                            <Button iconClass="k-icon k-i-calculator" className="quotesButton-mini"  onClick={this.handleQuotes}></Button>
                          </ButtonGroup>
                        </ToolbarItem>

                        <ToolbarItem>
                          <DropDownButton look="flat" icon="arrow-chevron-down" text={this.props.name.split(' ')[0].trim()==''?'Hola':this.props.name.split(' ')[0].trim()} onItemClick={this.handleAccount}>
                            <DropDownButtonItem icon="password" text="Cambiar Clave"/>
                            <DropDownButtonItem icon="logout" text="Cerrar sesión" />
                          </DropDownButton>                        
                        </ToolbarItem>

                      </Toolbar>
                  }
                    
                    </CardBody>

                  </Card>  
                </div>  
              
              </AppBarSection>

              <AppBarSection>
                <div>
                  <a className="icon-link" href={whatsappURL} target="_blank" title={"Contáctanos por Whatsapp"}>
                    <img height="32" src={whatsappImage}></img>
                  </a>
                </div>
              </AppBarSection>
                
            </AppBar>  

            <FloatCart {...this.props} showModal={this.props.showModal} name={this.props.name}/>

            <StyleRoot>
              <div 
                className="divSearchButton" 
                style={[localStyles.searchBar, { display: this.state.showSearch ? 'block' : 'none' }]}>
                  <ProductSearch {...this.props} styleTextBox={{width:'70%'}} style={{display:'flex', justifyContent:'center'}} />
              </div>
            </StyleRoot>

            <div className="content" >
                {this.props.children}
            </div>
            
            <style>{`
                    .header { padding: 20px; text-align: center; }
                    `}</style>            
            </div>
      );
     }    
};

export default withRouter(DrawerRouterContainer);
