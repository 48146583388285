import { UPDATE_FILTER } from './actionTypes';
import { UPDATE_FILTER_CATEGORY } from './actionTypes';

export const updateFilters = filters => ({
  type: UPDATE_FILTER,
  payload: filters
});

export const updateFiltersCategory = category => ({
  type: UPDATE_FILTER_CATEGORY,
  payload: category
});
