import React, { Component } from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { fetchProducts, clearProducts, searchProducts } from '../../services/shelf/actions';
import Filter from '../Shelf/Filter';
import Shelf from '../Shelf';
import LoginModal from '../LoginModal';

import './style.scss';

class Product extends Component{
    state = {
        isLoading: false,
        isProductOpen: false,
        searchText: ''
      };

    componentDidMount(){
        this.props.clearProducts();
        this.setState({ isLoading: true});
        this.props.fetchProducts([], "", "", "", "", this.props.match.params.id, () => {
          this.setState({ isLoading: false});
        });       
    }

    handleSearchChange = (e) => {
        this.setState({
          searchText: e.target.value
        })
      }
    
      handleSearchClick = () => {
        const { searchText } = this.state
        this.setState({ isLoading: true });
        this.props.searchProducts(searchText, () => {
          this.setState({ isLoading: false });
        });
      }

    render(){
        const { searchText } = this.state;
        const { products} = this.props;
        const {
          Content
        } = Layout;

        return (
          <div>

            <div className="clsSideMenu">
              {products.length > 0 && <Filter isItem={true} category={products[0].category} subcategory={products[0].categoryitem} />}
            </div>
          
            <Layout style={{ background: '#fff' }}>
              
              <Layout className="clsMainArea">
                <Content style={{
                  background: '#fff',
                  minHeight: 280,
                  overflow: 'initial'
                  }}>
                    <Shelf 
                      {...this.props} 
                      isItem={true} 
                      category={''} 
                      headertype={2} />
                    <LoginModal  
                      history={this.props.history}
                      handleCancel={this.props.handleCancel} 
                      handleOk={this.props.handleOk} 
                      visible={this.props.visible} 
                      handleLogin={this.props.handleLogin} 
                      handleLogoff={this.props.handleLogoff}/>
                </Content>
              </Layout>  
            </Layout>
            
          </div>
        )
    }
}

const mapStateToProps = state => ({
    products: state.shelf.products,
    filters: state.filters.items,
    sort: state.sort.type,
    selectedCategory: state.filters.selectedCategory
  });
  
  export default connect(
    mapStateToProps,
    { fetchProducts, clearProducts,searchProducts }
  )(Product);
  