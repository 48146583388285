// src/Landing.js
import React from 'react';
import { Layout } from 'antd';

import { Link } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import { baseRootURL } from '../../services/util';

import LoginModal from '../LoginModal';
import CategoryList from './CategoryList';

import Offer from './Offer';

const { Content } = Layout;

const carouselList = [
  { url: baseRootURL + "Custom/Charlie/Images/Website/Carousel/1.jpg", link: "", externalLink: 0, linkTarget: "_blank" },
  { url: baseRootURL + "Custom/Charlie/Images/Website/Carousel/2.jpg", link: "", externalLink: 0, linkTarget: "_blank" },
  { url: baseRootURL + "Custom/Charlie/Images/Website/Carousel/3.jpg", link: "", externalLink: 0, linkTarget: "_blank" },
];

const hotTopicsList = [
  { url: baseRootURL + "Custom/Charlie/Images/Website/HotTopics/1.png", background: baseRootURL + "Custom/Charlie/Images/Website/HotTopics/bg.jpg", link: "category/042/042-001", linkTarget: "_blank" },
  { url: baseRootURL + "Custom/Charlie/Images/Website/HotTopics/2.png", background: baseRootURL + "Custom/Charlie/Images/Website/HotTopics/bg.jpg", link: "category/018/all", linkTarget: "_blank" },
  { url: baseRootURL + "Custom/Charlie/Images/Website/HotTopics/3.png", background: baseRootURL + "Custom/Charlie/Images/Website/HotTopics/bg.jpg", link: "category/032/032-001", linkTarget: "_blank" },
  { url: baseRootURL + "Custom/Charlie/Images/Website/HotTopics/4.png", background: baseRootURL + "Custom/Charlie/Images/Website/HotTopics/bg.jpg", link: "category/027/027-009", linkTarget: "_blank" },
];

let Landing = (props) => {
  return(
    <>
      <Carousel 
        showStatus={false} 
        showThumbs={false} 
        infiniteLoop={true} 
        autoPlay={true}
        interval={10000}>
          {
            carouselList.map((itm, i) => {
              let vContent = [];
              const hasLink = itm.link && String(itm.link).trim() != "";
              const vTo = hasLink ? itm.link : "/";
              const vTarget = hasLink ? itm.linkTarget : "";
              const vTitle = hasLink ? "Ver detalles" : "";

              vContent.push(
                <div key={"c" + i} className={"clsCarouselElement"} title={vTitle}>
                  <img src={itm.url} />
                </div>
              );

              if (itm.externalLink == 0) {
                return(<Link key={"ce" + i} to={vTo} target={vTarget}>{vContent}</Link>);
              } else {
                return(<a key={"ce" + i} href={vTo} target={vTarget}>{vContent}</a>);
              }
            })
          }
      </Carousel>

      <Layout style={{ background: '#fff' }}>
        <Layout style={{ background: '#fff', width:'100%' }}>
          <Content style={{
            background: '#fff',
            paddingTop: '10px',
            overflow: 'initial',
            width:'98%'
            }}>

            <div style={{width:'100%', overflow:'hidden'}}>
              {
                hotTopicsList.map((itm, i) => {
                  let vContent = [];
                  const hasLink = itm.link && String(itm.link).trim() != "";
                  const vTo = hasLink ? itm.link : "/";
                  const vTarget = hasLink ? itm.linkTarget : "";
                  const vTitle = hasLink ? "Ver detalles" : "";

                  vContent.push(
                    <div key={"ht" + i} className={"clsHotTopic"} title={vTitle} style={{background:'url("' + itm.background + '")'}}>
                      <img src={itm.url} />
                    </div>
                  );
                  
                  return (<Link key={"hte" + i} to={vTo} target={vTarget}>{vContent}</Link>);
                })
              }
            </div>

            <Offer {...props} />
            <CategoryList {...props} />
            
            <LoginModal 
              history={props.history} 
              handleCancel={props.handleCancel} 
              handleOk={props.handleOk} 
              visible={props.visible} 
              handleLogin={props.handleLogin} 
              handleLogoff={props.handleLogoff}/>
          </Content>
        </Layout>  
      </Layout>  
    </>
  )
}

export default Landing;