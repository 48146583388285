import React from 'react';
import PropTypes from 'prop-types';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import { itemPicturesBase } from '../../services/util';

const Thumb = props => {
  let vContent = [];

  if (props.src) {
    vContent.push(
      <div key="1" className={props.classes} title={props.alt} style={{cursor:'pointer'}} >
        <img src={props.src} alt={props.alt} title={props.title} onError={addDefaultSrc}  />
      </div>
    );
  }

  if (props.productList) {
    vContent.push(
      <Carousel
        key="1" 
        showStatus={false} 
        showThumbs={false} 
        infiniteLoop={true} 
        autoPlay={false}>
          {
            props.productList.map((itm, i) => (
              <div key={"d" + i} style={{cursor:'pointer'}} className={props.classes} title={props.alt} onClick={() => props.toggleShowLightBox(props.productList)}>
                <img src={itemPicturesBase + itm} alt={props.alt} title={props.title} />
              </div>
            ))
          }
      </Carousel>
    );
  }
	
  return (vContent);
};


function addDefaultSrc(ev){
	ev.target.style = 'display:none';
}

Thumb.propTypes = {
  alt: PropTypes.string,
  title: PropTypes.string,
  classes: PropTypes.string,
  src: PropTypes.string
};

export default Thumb;
