// src/Landing.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'antd';
import { baseRootURL } from '../../services/util';

let Tile = (props) => {

  return(
    <div className="tile col-4" key={props.category}>
        <Link to={'/category/'+props.category+'/'+props.defaultSubcategory}>
            <div className="tile-content" style={{backgroundImage:`url(${baseRootURL}/${props.image})`}}>
                <div className="overlay">
                <div className="text-container">
                    {props.categoryDescription.toUpperCase()}
                    <br />
                    <span style={{fontSize:'80%'}}>
                        Ver productos
                        <Icon type="arrow-right" style={{fontSize:'80%', paddingLeft:'5px'}} />
                    </span>
                </div>
                </div>
            </div>
        </Link>
    </div>
    )
}

export default Tile;