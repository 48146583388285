//app01 ---> package.json line 45: "start": "set HTTPS=true&&react-scripts start",
export const baseURL = 'https://www.charlieinternacional.com/';

//dev01 ---> package.json line 45: "start": "set HTTPS=false&&react-scripts start",
//export const baseURL = 'http://charlie.promisan.net/';


export const baseAPI = 'Component/Process/EDI/Website/Store/Products.cfc?';
export const baseDocumentURL = baseURL + 'prosisdocument/';
export const baseRootURL = baseURL + 'apps/';

export const productsAPI = baseRootURL + baseAPI + 'method=GetAll';
export const categoriesAPI = baseRootURL + baseAPI + 'method=GetCategories';
export const loginAPI = baseRootURL + baseAPI + 'method=mobileLogin';
export const postOrderAPI = baseRootURL + baseAPI + 'method=postOrder';
export const setAnonymous = baseRootURL + baseAPI + 'method=setAnonymous';
export const getQuotes = baseRootURL + baseAPI + 'method=getQuotes';
export const pwdChange = baseRootURL + baseAPI + 'method=mobilePwdChange';
export const pwdReset = baseRootURL + baseAPI + 'method=mobilePwdReset';

export const mainLogo = baseRootURL + 'Custom/CHARLIE/images/Charlie-2019-w-w.png';
export const mainLogoMini = baseRootURL + 'Custom/CHARLIE/images/Charlie-2019-w-mini.png';

export const newUser = baseURL + 'website/NewAccount/Request.cfm';

export const whatsappImage = baseRootURL + 'Custom/CHARLIE/images/whatsapp32.png';
export const whatsappURL = 'https://wa.me/50258688533?text=Hola%2C%20me%20podría%20dar%20información%20acerca%20del%20artículo...';
export const whatsappContactDisplay = '+502 5868-8533';

export const itemPicturesBase = baseDocumentURL + 'Warehouse/Pictures/';
export const defaultCategoryImage = 'Custom/Charlie/Images/Categories/default.png';
export const defaultCategorySort = 'ASC';
export const defaultProductsSort = 'priceASC'; //catASC, descASC, stockASC, priceASC, discountASC
export const defaultSubcategoryType = 'first'; //all, first, last

export const showCartOnAdd = false;
export const cartNotificationDuration = 4;

export const landingTopNOffers = 40;
export const landingOfferWidth = 200;
export const maxProductsResults = 200;
export const maxCharsQuoteDescription = 50;
export const itemsPerPageQuote = 20;

