import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Menu, Icon } from 'antd';

import 'antd/lib/style';
import 'antd/lib/menu/style';
import 'antd/dist/antd.css';
import 'rc-drawer/assets/index.css';

import { updateFilters } from '../../../services/filters/actions';
import { updateFiltersCategory } from '../../../services/filters/actions';
import { fetchCategories, clearCategories } from '../../../services/shelf/actions';
import Category from '../../Category';
import Utilities from '../../../Utilities';

const SubMenu = Menu.SubMenu;

class Filter extends Component {
  static propTypes = {
    updateFilters: PropTypes.func.isRequired,
    updateFiltersCategory: PropTypes.func.isRequired,
    fetchCategories: PropTypes.func,
    filters: PropTypes.array,
    categories: PropTypes.array,
    selectedCategory : PropTypes.string
  };

  state = {
    isLoading: false,
    open: true
  };

  componentDidMount() {
    let fnCI = this.toggleCategoryItem;
    let vSubC = this.props.subcategory;
    let vIsItem = this.props.isItem;

    this.props.clearCategories();
    this.setState({ isLoading: true });
    this.props.fetchCategories('', '', this.props.category, () => {
      if (!vIsItem && vSubC != 'all') {
        fnCI(vSubC);
      }
      this.setState({ isLoading: false });
    });
    this.selectedCheckboxes = new Set();
    this.afterSelect();
  }


  toggleCategoryItem = label => {
    this.selectedCheckboxes = new Set();
    this.selectedCheckboxes.add(label);
    this.setState({open: false});
    this.props.updateFilters(Array.from(this.selectedCheckboxes));
    this.afterSelect();
  };


  toggleCategory = category => {
    this.props.updateFiltersCategory(category);
    this.afterSelect();
  };  

  createCheckbox = label => (
    <Category
      classes="filters-available-size"
      label={label}
      handleCheckboxChange={this.toggleCheckbox}
      key={label}
    />
  );

  createCheckboxes = () => this.props.categories.map(this.createCheckbox);

  onClick = () => {
    this.setState({
      open: !this.state.open
    });
  }

  renderDesktopNavbar = () => {

  }
  
  renderMobileNavbar = () => {
  }  

  afterSelect = () => {
    Utilities.goToTop();
  }

  render() {
    const { categories } = this.props;
    const { isLoading } = this.state;

    return (
              
        <div className="filters">
            
          <Menu
            defaultSelectedKeys={[`key-${this.props.category}-${this.props.subcategory}`]}
            defaultOpenKeys={[`key-${this.props.category}`]}
            style={{padding:'0px', margin:'0px', borderRight:'0px', overflowX:'hidden', width:'250px'}}
            inlineIndent={10}
            mode="inline">
          { 
            this.props.categories.map(category=>  
              <SubMenu
                key={`key-${category.category}`}
                title={<span style={{fontSize:'125%', color:'#404040'}}>{category.categorydescription}</span>}>
                  <Menu.Item  
                    key={`key-${category.category}-all`} 
                    style={{height:'22px', lineHeight:'22px', fontSize:'90%', padding:'0px', paddingLeft:'15px!important', marginBottom:'5px'}}
                    onClick={() => this.toggleCategory(category.category)}>
                      <Link to={`/category/${category.category}/all`}>
                        <Icon type="right" style={{fontSize:'80%'}} />
                        TODO
                      </Link>     
                  </Menu.Item>
                  {
                    category.SUBCATEGORIES.map(subitem => {
                      return (
                        <Menu.Item  
                          key={`key-${category.category}-${subitem.categoryitem}`} 
                          style={{height:'22px', lineHeight:'22px', fontSize:'90%', padding:'0px', paddingLeft:'15px!important', marginBottom:'5px'}}
                          onClick={() => this.toggleCategoryItem(subitem.categoryitem)}>
                            <Link to={`/category/${category.category}/${subitem.categoryitem}`}>
                              <Icon type="right" style={{fontSize:'80%'}} />
                              {subitem.categoryitemname}
                            </Link>     
                        </Menu.Item>
                      )
                    })
                  }
              </SubMenu> 
            )
          }
            
          </Menu>

        </div>
    );
  }
}

const mapStateToProps = state => ({
  categories: state.shelf.categories,
  filters: state.filters.items,
  selectedCategory : state.selectedCategory
});

const mapActions = {
  updateFilters: updateFilters,
  updateFiltersCategory: updateFiltersCategory,
  fetchCategories: fetchCategories,
  clearCategories: clearCategories
};

export default connect(
  mapStateToProps,
  mapActions
)(Filter);
