import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { fetchProducts, searchProducts, clearProducts } from '../../services/shelf/actions';
import { updateSort } from '../../services/sort/actions';
import { notification } from "antd";
import Spinner from '../Spinner';
import ShelfHeader from './ShelfHeader';
import ProductList from './ProductList';
import { itemPicturesBase } from '../../services/util';

import './style.scss';

class Shelf extends Component {
  static propTypes = {
    fetchProducts: PropTypes.func,
    clearCategories: PropTypes.func,
    products: PropTypes.array,
    filters: PropTypes.array,
    sort: PropTypes.string,
    selectedCategory : PropTypes.string,
    selectedSubcategory: PropTypes.string
  };

  state = {
    isLoading: false,
    isProductOpen: false,
    searchText: '',
    photoIndex: 0
  };

  componentDidMount() {
    this.props.clearProducts();
    if (this.props.category == "" && !this.props.isItem) {
      //Search
      this.handleFetchProducts([], this.props.sort, this.props.searchtext, "", false);
    } else {
      this.setState({ selectedCategory: this.props.category }, () => {
        if (this.props.subcategory == 'all') {
          this.handleFetchProducts(this.props.filters, this.props.sort, '', this.props.category, this.props.isItem);
        }
      });
    }
  }

  toggleShowLightBox = (vImage ="") => {
    this.setState({
      isProductOpen: !this.state.isProductOpen,
      vImage
    })
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.isItem) {
      const { filters: nextFilters, sort: nextSort, selectedCategory: nextSelectedCategory, itemNo : nextItemNo } = nextProps;
      
      if (nextProps.subcategory != 'all') {
        
        if (nextFilters != this.props.filters) {
          this.setState({
            searchText: ''
          });
          this.handleFetchProducts(nextFilters, undefined, undefined, undefined, false);
        }

        if (nextSort != this.props.sort) {
          if (nextProps.category != '' && nextProps.filters.length > 0) {
            this.handleFetchProducts(nextProps.filters, nextSort, undefined, undefined, false);
          }
        }

        if (nextSelectedCategory != this.props.selectedCategory)
        {
          this.setState({
            searchText: ''
          });
          this.handleFetchProducts(undefined, undefined, undefined, nextSelectedCategory, false);
        }

        if (this.props.category == "" && this.props.searchtext != nextProps.searchtext) {
          //Search
          this.handleFetchProducts([], this.props.sort, nextProps.searchtext, "", false);
        }

      } else {

        if (this.props.subcategory != nextProps.subcategory) {
          this.setState({
            searchText: ''
          });
          this.handleFetchProducts(nextFilters, undefined, undefined, this.props.category, false);
        }

        if (nextSort != this.props.sort) {
          this.handleFetchProducts(undefined, nextSort, undefined, this.props.category, false);
        }

      }
    }
  }

  handleSearchChange = (e) => {
    this.setState({
      searchText: e.target.value
    })
  }

  handleSearchClick = () => {
    if (this.state.searchText && this.state.searchText.length >= 3) {
      this.props.history.push('/search/'+this.state.searchText);
    } else {
      notification.info({
        message:'Su búsqueda debe contener al menos 3 caracteres.'
      });
    }
  }

  handleFetchProducts = (
    filters = this.props.filters,
    sort = this.props.sort,
    searchText,
    selectedCategory,
    isItem = this.props.isItem
  ) => {
    
    if (!isItem)
    {
      this.setState({ isLoading: true });
      this.props.fetchProducts(filters, sort, searchText, selectedCategory, "", "", () => {
        this.setState({ isLoading: false});
      });
    } 
  }

  render() {
    const { products } = this.props;
    const { isLoading, isProductOpen, vImage, searchText } = this.state;
    let vProdsLength = 0;
    if (products) {
      vProdsLength = products.length
    }

    return (
      <React.Fragment>
        {isLoading && <Spinner />}
        <div className="shelf-container">
          <ShelfHeader 
            {...this.props}
            productsLength={vProdsLength} 
            handleSearchClick={this.handleSearchClick} 
            handleSearchChange={this.handleSearchChange} 
            searchProducts={this.handleFetchProducts}
            updateSort={this.props.updateSort}
            searchText={searchText} 
            headertype={this.props.headertype} 
            searchtext={this.props.searchtext} 
            sort={this.props.sort} />
          <ProductList 
            products={products} 
            isProductOpen={isProductOpen} 
            toggleShowLightBox={this.toggleShowLightBox} 
            category={this.props.category} />
          {isProductOpen && (
          <Lightbox
            mainSrc={itemPicturesBase + vImage[this.state.photoIndex]}
            nextSrc={itemPicturesBase + vImage[(this.state.photoIndex + 1) % vImage.length]}
            prevSrc={itemPicturesBase + vImage[(this.state.photoIndex + vImage.length - 1) % vImage.length]}            
            onCloseRequest={this.toggleShowLightBox}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (this.state.photoIndex + vImage.length - 1) % vImage.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (this.state.photoIndex + 1) % vImage.length,
              })
            }            
          />
        )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  products: state.shelf.products,
  filters: state.filters.items,
  sort: state.sort.type,
  selectedCategory: state.filters.selectedCategory
});

const mapActions = {
  fetchProducts: fetchProducts,
  searchProducts: searchProducts,
  clearProducts: clearProducts,
  updateSort: updateSort
};

export default connect(
  mapStateToProps,
  mapActions
)(Shelf);
