import React from 'react';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import 'antd/dist/antd.css';
import Utilities from '../../Utilities';
import LoginModal from '../LoginModal';
import { newUser } from '../../services/util';

const {
  Content
} = Layout;

let NewUser = (props) => {

    let routes = [{
        path: '/',
        breadcrumbName: 'Inicio'
    }];

    routes.push({
        path:props.history.location.pathname, 
        breadcrumbName: Utilities.getPageName(props.history.location.pathname)
    });

    const itemRender = (route, params, routes, paths) => {
        const last = routes.indexOf(route) === routes.length - 1;
        return last ? <span>{route.breadcrumbName}</span> : <Link to={paths.join('/')}>{route.breadcrumbName}</Link>;
    }

    const iframe = () => {
        return {
          __html: '<iframe src="'+newUser+'" width="100%" height="1000" frameborder="0" scrolling="no"></iframe>'
        }
    }
    
    return(
    <>
        <Layout style={{ background: '#fff' }}>
        <Layout className="clsMainArea" style={{ background: '#fff' }}>
            <Content style={{
            background: '#fff',
            minHeight: 1000,
            overflow: 'initial',
            width:'100%',
            margin: '0 auto'
            }}>
            <div style={{width:'100%', paddingBottom:'10px', paddingLeft:'20px'}}>
                <Breadcrumb itemRender={itemRender} routes={routes} />
            </div>
            <div dangerouslySetInnerHTML={iframe()}></div>
            <LoginModal  
                history={props.history}
                handleCancel={props.handleCancel} 
                handleOk={props.handleOk} 
                visible={props.visible} 
                handleLogin={props.handleLogin} 
                handleLogoff={props.handleLogoff}/>
            </Content>
        </Layout>  
        </Layout>

    </>

    )
}

export default NewUser;