import { LOAD_CART, ADD_PRODUCT, REMOVE_PRODUCT, UPDATE_PRODUCT } from './actionTypes';

const initialState = {
  products: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOAD_CART:
      return {
        ...state,
        products: action.payload
      };
    case ADD_PRODUCT:
      return {
        ...state,
        productToAdd: Object.assign({}, action.payload)
      };
    
    case UPDATE_PRODUCT:
      const newProducts =  state.products.map((product)=> {
        if(product.itemno === action.payload.itemno){
          product = action.payload
        }
        return product

      });

      const oldState = JSON.parse(window.localStorage.getItem('state'))
      oldState.cart.products = newProducts
      window.localStorage.setItem('state', oldState);

     return {
      ...state, 
      products: newProducts
    }
      
    case REMOVE_PRODUCT:
      return {
        ...state,
        productToRemove: Object.assign({}, action.payload)
      };
    default:
      return state;
  }
}
