import { notification } from "antd";
import { showCartOnAdd, cartNotificationDuration } from "../services/util";

const Utilities = {
	formatNumber: function(num) {
		if (num) return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		return 0;
	},

	formatPrice: function(num) {
		if (num) {
			var parts = num.toFixed(2).toString().split(".");
    		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    		return parts.join(".");
    	}
    	return 0.00;
	},

	formatDateTime: function(strDate) {
		let vReturn = String(strDate).trim();
		let vThisDate = new Date(String(strDate).trim());

		vReturn = vThisDate.getDate() < 10 ? '0' + String(vThisDate.getDate()) : String(vThisDate.getDate());
		vReturn = vReturn + '/' 
				+ ((vThisDate.getMonth() + 1) < 10 ? '0' + String(vThisDate.getMonth() + 1) : String(vThisDate.getMonth() + 1));	
		vReturn = vReturn + '/' 
				+ String(vThisDate.getFullYear());

		let vAMPMHours = vThisDate.getHours() > 12 ? vThisDate.getHours() - 12 : vThisDate.getHours();
		vReturn = vReturn + ' ' 
				+ (vAMPMHours < 10 ? '0' + String(vAMPMHours) : String(vAMPMHours));
		vReturn = vReturn + ':' 
				+ (vThisDate.getMinutes() < 10 ? '0' + String(vThisDate.getMinutes()) : String(vThisDate.getMinutes()));

		let vAMPMLabel = vThisDate.getHours() > 12 ? 'PM' : 'AM';
		vReturn = vReturn + ' ' + vAMPMLabel;

		return vReturn;
	},

	goToTop: function() {
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	},

	getPageName: function(rawName) {
		let vPageName = rawName.split("/")[1];
		let vReturn = "";

		if (vPageName == "") { vReturn = "Inicio"; }
		if (vPageName == "search") { vReturn =  "Búsqueda"; }
		if (vPageName == "product") { vReturn =  "Producto"; }
		if (vPageName == "category") { vReturn =  "Categoría"; }
		if (vPageName == "newuser") { vReturn =  "Crear Usuario"; }
		if (vPageName == "quotes") { vReturn =  "Estado de cotizaciones"; }
		if (vPageName == "password") { vReturn =  "Cambiar contraseña"; }
		if (vPageName == "passwordreset") { vReturn =  "Reiniciar contraseña"; }

		return vReturn;
	},

	redirectToHomeFromPublic: function(history) {
		if (history.location.pathname == '/newuser' 
        	|| history.location.pathname == '/passwordreset') {
				history.push('/');
		}
	},

	validateProductQuantity: function(thisProduct, cartProduct, itemAmount) {
		const priceSchedule = window.localStorage.getItem('priceSchedule');
		const default_priceSchedule = window.localStorage.getItem('priceDefaultSchedule');

		//No price, cannot add to the cart
		if (thisProduct.salesprice == null || String(thisProduct.salesprice).trim() == '') { 
			return(cartProduct); 
		}

		//New product: creates a copy, compares with itself and sets the current/default priceSchedule
		if (!cartProduct) { 
			cartProduct = thisProduct; 
			cartProduct.priceSchedule = priceSchedule ? priceSchedule : default_priceSchedule;
		}

		//If no priceSchedule
		if (parseInt(priceSchedule) == parseInt(default_priceSchedule) || priceSchedule == '' || priceSchedule === null) {
			//If it comes from the 'add to cart' button
			if (itemAmount == undefined) {
				if (thisProduct.onhand >= (cartProduct.quantity + 1)) { 
					cartProduct.quantity += 1; 
				}
			//Changing the amounts within the cart
			} else {
				if (itemAmount > 0) {
					if (thisProduct.onhand >= itemAmount) { cartProduct.quantity = itemAmount; }
				}
			}
		//With a defined priceSchedule
		} else {
			//If it comes from the 'add to cart' button
			if (itemAmount == undefined) {
				const vCTN = (thisProduct.offerminimumquantity != null && String(thisProduct.offerminimumquantity).trim() != '' ) ? thisProduct.offerminimumquantity : 1;

				if (thisProduct.onhand >= (cartProduct.quantity + vCTN)) {
					cartProduct.quantity += vCTN;
				} else {
					cartProduct.quantity = thisProduct.onhand;
				}   
			//Changing the amounts within the cart
			} else {
				if (itemAmount > 0 && itemAmount <= thisProduct.onhand) {
					cartProduct.quantity = itemAmount;
				}
			} 
		}

		//Returns the updated product
		return(cartProduct);
	},

	addProductToCart: function(product, itemAmount, cartProducts, updateCart, openFloatCart, fromCart) {
		const fnValidateProductQuantity = this.validateProductQuantity;
		const fnOpenFloatCart = () => {
			if (!!openFloatCart) { openFloatCart(); }
		}
		const fnOpenFlotCartFromNotification = (pkey) => {
			notification.close(pkey);
			fnOpenFloatCart();
		}
		let productAlreadyInCart = false;
		let itemsAdded = 0;

		//Searches the product in the cart
		cartProducts.forEach(cp => {
			if (cp.itemno === product.itemno) {
				let thisQuantity = cp.quantity;
				//compares the product to add with the found product
				cp = fnValidateProductQuantity(product, cp, itemAmount);
				productAlreadyInCart = true;
				itemsAdded = cp.quantity - thisQuantity;
			}
		});

		//New product to add to the cart
		if (!productAlreadyInCart) {
			//Defines a new product and adds it to the beginning of the list
			let vNewProduct = fnValidateProductQuantity(product, null, itemAmount);
			if (vNewProduct) { 
				cartProducts.unshift(vNewProduct); 
				itemsAdded = product.quantity;
			}
		}

		updateCart(cartProducts);

		//Inform user
		if (!fromCart) {
			const vThisKey = String(product.itemnoexternal) + String(new Date().getMilliseconds());

			if (itemsAdded > 0) {
				let vSuffix = '';
				if (itemsAdded > 1) { vSuffix = 's'; }
				//inform user
				notification.success({
					key: vThisKey,
					message: this.formatNumber(itemsAdded)
						+ ' producto' + vSuffix 
						+ ' agregado' + vSuffix 
						+ ' a su carrito',
					description: String(product.itemnoexternal).toUpperCase() + ' - ' + String(product.itemdescription).toUpperCase(),
					onClick: () => { fnOpenFlotCartFromNotification(vThisKey) },
					className: 'clsNotification',
					duration: cartNotificationDuration
				});
			}
			if (itemsAdded == 0) {
				//inform user
				notification.error({
					key: vThisKey,
					message: 'No se cuenta con mas existencia de este producto',
					description: String(product.itemnoexternal).toUpperCase() + ' - ' + String(product.itemdescription).toUpperCase(),
					onClick: () => { fnOpenFlotCartFromNotification(vThisKey) },
					className: 'clsNotification',
					duration: cartNotificationDuration
				});
			}
		}
		
		//open cart
		if (!!showCartOnAdd) { fnOpenFloatCart(); }
	},

	getDefaultSubcategory: function(defaultSubcategoryType, category) {
		let vReturn = 'all';
		
		switch (String(defaultSubcategoryType).trim().toLowerCase()) {
			case 'all': 
				vReturn = 'all';
				break;
			case 'first':
				vReturn = category.SUBCATEGORIES[0].categoryitem;
				break;
			case 'last':
				vReturn = category.SUBCATEGORIES[category.SUBCATEGORIES.length-1].categoryitem;
				break;
			default: 
				vReturn = 'all';
		}

		return vReturn;
	},

	getOrderingOptions: function() {
		return [
			{ value: 'catASC', label: 'Categoría A-Z' },
			{ value: 'catDESC', label: 'Categoría Z-A' },
			{ value: 'descASC', label: 'Descripción A-Z' },
			{ value: 'descDESC', label: 'Descripción Z-A' },
			{ value: 'stockASC', label: 'Mayor Existencia' },
			{ value: 'stockDESC', label: 'Menor Existencia' },
			{ value: 'priceDESC', label: 'Mayor Precio' },
			{ value: 'priceASC', label: 'Menor Precio' },
			{ value: 'discountASC', label: 'Mayor Descuento' },
			{ value: 'discountDESC', label: 'Menor Descuento' }  
		];
	},

	getOS: function() {
		var userAgent = window.navigator.userAgent,
			platform = window.navigator.platform,
			macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
			windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
			iosPlatforms = ['iPhone', 'iPad', 'iPod'],
			os = null;
	  
		if (macosPlatforms.indexOf(platform) !== -1) {
		  os = 'Mac';
		} else if (iosPlatforms.indexOf(platform) !== -1) {
		  os = 'iOS';
		} else if (windowsPlatforms.indexOf(platform) !== -1) {
		  os = 'Windows';
		} else if (/Android/.test(userAgent)) {
		  os = 'Android';
		} else if (!os && /Linux/.test(platform)) {
		  os = 'Linux';
		}
	  
		return os;
	}
}

export default Utilities;