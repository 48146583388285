import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Thumb from './../../Thumb';
import Utilities from '../../../Utilities';
import { itemPicturesBase } from '../../../services/util';

class CartProduct extends Component {
  static propTypes = {
    product: PropTypes.object.isRequired,
    cartProducts: PropTypes.array.isRequired,
    removeProduct: PropTypes.func.isRequired,
    updateProduct: PropTypes.func.isRequired,
    updateCart: PropTypes.func.isRequired,
    openFloatCart: PropTypes.func,
    closeFloatCart: PropTypes.func
  };

  state = {
    isMouseOver: false,
    quantity: this.props.product.quantity
  };

  handleMouseOver = () => {
    this.setState({ isMouseOver: true });
  };

  handleMouseOut = () => {
    this.setState({ isMouseOver: false });
  };

  handleQuantityChange = (e) => {
    e.preventDefault();
    Utilities.addProductToCart(
        this.props.product, 
        parseInt(e.target.value), 
        this.props.cartProducts, 
        this.props.updateCart, 
        undefined, 
        true);
  }

  render() {
    const { product, removeProduct } = this.props;

    const classes = ['shelf-item'];

    let vImage = (product.PICTURE != null && product.PICTURE.length > 0) ? itemPicturesBase + product.PICTURE[0] : '';

    if (!!this.state.isMouseOver) {
      classes.push('shelf-item--mouseover');
    }

    const price = (product.salesprice == null || String(product.salesprice).trim() == '') ? 0 : product.salesprice;;


    return (
      <div className={classes.join(' ')}>
        <div
          className="shelf-item__del"
          onMouseOver={() => this.handleMouseOver()}
          onMouseOut={() => this.handleMouseOut()}
          onClick={() => removeProduct(product)} />
        <Link 
          to={'/search/'+String(product.itemnoexternal)} 
          onClick={() => this.props.closeFloatCart()}
          style={{color:'#5da5e6'}}
          title="Ver producto">
            <Thumb
              classes="shelf-item__thumb"
              src={vImage}
              alt={"Ver producto"} />
        </Link>
        <div className="shelf-item__details">
          <div className="title" style={{fontSize:'85%'}}>
            <Link 
              to={'/search/'+String(product.itemnoexternal)}
              onClick={() => this.props.closeFloatCart()} 
              style={{color:'#5da5e6'}}
              title="Ver producto">
                {product.itemdescription.toUpperCase()}
            </Link>
          </div>
          <div className="title" style={{lineHeight:'12px', fontSize:'90%'}}>
            <small>
              {product.itemnoexternal}
              {
                price.OfferMinimumQuantity!=''?
                  <span>&nbsp;-&nbsp;CTN:&nbsp;{Utilities.formatNumber(product.offerminimumquantity)}</span>
                :
                ''  
              }    
            </small>       
          </div>
          <input type="number" className="float-cart__quantity" value={product.quantity} onChange={this.handleQuantityChange}/>
        </div>
        <div className="shelf-item__price">
          <p>{`${product.currencysymbol}  ${Utilities.formatPrice(product.salesprice)}`}</p>
        </div>
      </div>
    );
  }
}

export default CartProduct;
