
import React from 'react';
import { Link } from 'react-router-dom';
import { notification, Modal, Form, Icon, Input, Button } from 'antd';
import axios from 'axios';
import { loginAPI } from '../../services/util';


function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class LoginModal extends React.Component {
   
  componentDidMount() {
    // To disable submit button at the beginning.
    this.props.form.validateFields();
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        
        var bodyFormData = new FormData();

        bodyFormData.set('user', values.username);
        bodyFormData.set('pwd', values.password);
        bodyFormData.set('deviceId', 'portal');

        var optionAxios = {
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
          }
        }           
        
        axios.post(loginAPI,bodyFormData, optionAxios
            ).then(res => {
                     console.log(res);
                     if (res.data[0]) {
                     //console.log(res.data[0].account);   
                     //console.log(res.data[0].name);   
                     if (res.data[0].account) {
                         if (res.data[0].name!='') {
                            var addresses = JSON.stringify(res.data[0].ADDRESS);
                            this.props.handleLogin(res.data[0].name, res.data[0].account,res.data[0].PRICESCHEDULE,addresses,res.data[0].customerid);
                            this.props.handleOk(this.props.history);
                         } else {
                            this.props.handleLogoff();
                            notification.error({ 
                              message:'Usuario o contraseña incorrecta',
                              description:'Por favor intente de nuevo'
                            });
                         }
                     } else {
                        this.props.handleLogoff();
                        notification.error({ 
                          message:'Usuario o contraseña incorrecta',
                          description:'Por favor intente de nuevo'
                        });
                     }
                    } else {
                      notification.error({ 
                        message:'Usuario o contraseña incorrecta',
                        description:'Por favor intente de nuevo'
                      });
                    }
            });
    };
  })
}

  render() {
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;

    // Only show error after a field is touched.
    const usernameError = isFieldTouched('username') && getFieldError('username');
    const passwordError = isFieldTouched('password') && getFieldError('password');
    return (
        <Modal
        title="Ingresar"
        visible={this.props.visible}
        onOk={this.props.handleOk}
        onCancel={this.props.handleCancel}
        footer={null}
        >
     
      <Form layout="inline" onSubmit={this.handleSubmit}>
        <Form.Item validateStatus={usernameError ? 'error' : ''} help={usernameError || ''}>
          {getFieldDecorator('username', {
            rules: [{ required: true, message: 'Favor ingresar usuario' }],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Usuario"
            />,
          )}
        </Form.Item>
        <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError || ''}>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Favor ingresar contraseña' }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Contraseña"
            />,
          )}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>
            Ingresar
          </Button>
        </Form.Item>
      </Form>
      
      <div style={{paddingTop:'10px'}}>
        ¿No tiene usuario? <Link to={'/newuser'} onClick={this.props.handleCancel}>Cree uno nuevo aquí.</Link>
      </div>
      
      </Modal> 
    );
  }
}

export default Form.create({})(LoginModal);