// src/Home.js
import React from 'react';
import { Layout } from 'antd';
import Shelf from '../Shelf';
import LoginModal from '../LoginModal';
import { useParams } from "react-router";

const {
  Header, Content, Sider
} = Layout;

let Search = (props) => {

  let { searchtext } = useParams();

  return(
    <>
  
        <Layout style={{ background: '#fff' }}>
          <Layout className="clsMainArea" style={{ background: '#fff' }}>
            <Content style={{
              background: '#fff',
              minHeight: 280,
              overflow: 'initial'
              }}>
              <Shelf 
                {...props} 
                isItem={false} 
                category={''} 
                searchtext={searchtext} 
                headertype={3} />
              <LoginModal  
                history={props.history}
                handleCancel={props.handleCancel} 
                handleOk={props.handleOk} 
                visible={props.visible} 
                handleLogin={props.handleLogin} 
                handleLogoff={props.handleLogoff}/>
            </Content>
          </Layout>  
        </Layout>
      
  </>

    )
}

export default Search;